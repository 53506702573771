import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 팝업정보
 */
 class ProjectExposure {
    /**
     * 생성자
     */
    constructor() {

    }

    /**
     * 프로젝트 정보 목록 조회
     * @param {String} krtMain 
     * @param {String} vflag_Y_koreit 
     * @param {String} vflag_A_koreit 
     * @param {String} vflag_B_koreit 
     * @param {String} vflag_C_koreit 
     * @param {String} vflag_D_koreit 
     * @param {String} vflag_E_koreit 
     * @param {String} vflag_koaroo 
     * @returns 
     */
     getProjectExposure(krtMain, vflag_Y_koreit, vflag_A_koreit, vflag_B_koreit, vflag_C_koreit, vflag_D_koreit, vflag_E_koreit, vflag_koaroo) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Cims.Biz.CimsPrjInfoBiz';
            request.methodId = 'GetProjectExposure';
            request.parameters.krtMain = krtMain
            request.parameters.vflag_Y_koreit = vflag_Y_koreit
            request.parameters.vflag_A_koreit = vflag_A_koreit
            request.parameters.vflag_B_koreit = vflag_B_koreit
            request.parameters.vflag_C_koreit = vflag_C_koreit
            request.parameters.vflag_D_koreit = vflag_D_koreit
            request.parameters.vflag_E_koreit = vflag_E_koreit
            request.parameters.vflag_koaroo = vflag_koaroo

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 프로젝트 정보 등록
     * @param {Any} detail 
     * @returns 
     */
     saveTabTableList(detail) {
            return new Promise((resolve, reject) => {
                
                const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
                const service = new FoxRestBizClient(url);
                const request = new FoxBizRequest();
                request.classId = 'Site.Cims.Biz.CimsPrjInfoBiz'
                request.methodId = 'UpdateProjectExposure'
                request.parameters.projectExposureList = detail
    
                service.execute(request).then(response => {
    
                    resolve(response.data)
                }).catch(error => reject(error));
            });
        }
}

export const projectExposure = new ProjectExposure();