<template>
    <div class="admin-content">
        <h3>사업노출 설정</h3>
        <div class="flex-wrap right">
			<div class="ml10">
				<div class="btn-wrap">
					<button type="button" class="btn-box" @click="methods.getProjectExposure">조회</button>
					<button type="button" class="btn-box gray"  @click="methods.saveTabTableList">저장</button>
				</div>
			</div>
			<div>
				<div class="sch-wrap3">
					<div>
						<label>메인노출</label>
						<div class="select-box">
                            <select v-model="state.dropDown.selectedMain">
                                <option value="">전체</option>
                                <option value="Y">표시</option>
                                <option value="N">미표시</option>
                            </select>
                        </div>
					</div>
					<div>
						<label>사이트노출</label>
						<div>
							<MultiSelect 
                            id="drpKoreit"
                            v-model="state.dropDown.selectedKoreit" 
                            optionLabel="CodeName" 
                            optionValue="CodeValue" 
                            :options="state.dropDown.koreitItems"/>
						</div>
					</div>
					<div>
						<label>코아루대상</label>
						<div class="select-box">
                            <select id="selecKoaroo" v-model="state.dropDown.selectedKoaroo">
                                <option value="">전체</option>
                                <option value="Y">대상</option>
                                <option value="N">비대상</option>
                            </select>
                        </div>
					</div>
				</div>
			</div>
		</div>

        <div class="total-box">
			<p>Total <strong>{{state.dataTable.projectItems === null ? 0 : state.dataTable.projectItems.length}}</strong></p>
		</div>
        
        <DataTable 
            class="p-datatable-sm" 
            columnResizeMode="fit"
            scrollHeight="497px"
            :value="state.dataTable.projectItems"
            :resizableColumns="true"
            :rowHover="true">

            <template #empty>
                데이터가 없습니다.
            </template>
            
            <Column field="idx" header="번호" style="width:90px; text-align:center;" headerClass="tc"></Column>
            <Column field="pname" header="프로젝트명" headerClass="tc"></Column>
            <Column field="krtMain" header="메인구분" style="width:220px" headerClass="tc">
                <template #body="slotProps">
                    <div style="display: flex; justify-content: center;">
                        <div class="select-box">
                            <select v-model="slotProps.data.krtMain" @change="methods.getChange(slotProps.data)" >
                                <option value="Y">표시</option>
                                <option value="N">미표시</option>
                            </select>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="vflag_koreit" header="사이트노출" style="width:300px" headerClass="tc">
                <template #body="slotProps">
                    <div style="display: flex; justify-content: center;">
                        <MultiSelect 
                            optionLabel="CodeName" 
                            optionValue="CodeValue" 
                            placeholder="Noting Selected"
                            :model-value="methods.setKoreit(slotProps.data.vflag_koreit)"
                            :options="state.dataTable.koreitItems" 
                            @update:model-value="(e) => slotProps.data.vflag_koreit = e" 
                            @change="methods.getChange(slotProps.data)"/>
                    </div>
                </template>
            </Column>
            <Column field="vflag_koaroo" header="코아루대상" style="width:220px" headerClass="tc">
                <template #body="slotProps">
                    <div style="display: flex; justify-content: center;">
                        <div class="select-box">
                            <select v-model="slotProps.data.vflag_koaroo" @change="methods.getChange(slotProps.data)">
                                <option value="Y">대상</option>
                                <option value="N">비대상</option>
                            </select>
                        </div>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { projectExposure } from '@/modules/cims/CIM_CM0301E.js';
import alertManager from '@/utils/alert-confirm-manager';
export default {
    setup() {
        // 스토어
        const store = useStore();

        const state = reactive({
            dataTable: {

                // 프로젝트 목록
                projectItems: new Array(),

                // 수정된 프로젝트 목록
                modifyItems : new Array(),

                // 사이트노출
                koreitItems: [
                    { CodeValue: "C", CodeName: "공사정보" },
                    { CodeValue: "D", CodeName: "입주정보" },
                    { CodeValue: "E", CodeName: "미노출" },
                ],
            },
            dropDown: {
                // 선택된 메인구분
                selectedMain: "",

                // 선택된 사이트노출
                selectedKoreit: ["Y", "C", "D", "E"],

                // 선택된 코아루대상
                selectedKoaroo: "",

                // 사이트노출
                koreitItems: [
                    { CodeValue: "Y", CodeName: "없음" },
                    { CodeValue: "C", CodeName: "공사정보" },
                    { CodeValue: "D", CodeName: "입주정보" },
                    { CodeValue: "E", CodeName: "미노출" },
                ],
            }
        })

        // 메서드
        const methods = {
            // 프로젝트 조회
            getProjectExposure: () => {
                let y = null;
                let c = null;
                let d = null;
                let e = null;

                // 사이트노출 인수 값 설정
                if (state.dropDown.selectedKoreit != null) {
                    if (state.dropDown.selectedKoreit.includes('Y')) {
                        y = 'Y';
                    }
                    if (state.dropDown.selectedKoreit.includes('C')) {
                        c = 'C';
                    }
                    if (state.dropDown.selectedKoreit.includes('D')) {
                        d = 'D';
                    }
                    if (state.dropDown.selectedKoreit.includes('E')) {
                        e = 'E';
                    }
                }
                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                projectExposure.getProjectExposure(state.dropDown.selectedMain, y, null, null, c, d, e, state.dropDown.selectedKoaroo).then(result => 
                    state.dataTable.projectItems = result

                ).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    state.dataTable.modifyItems = new Array();
                });
            },

            // 멀티셀렉트 값 설정
            setKoreit: (value) => {

                if (Array.isArray(value)) {
                    if (value[0] === "") {
                        value.shift();
                    }
                    return value;
                }
                else {
                    if (!!value.length) {
                        let arr = new Array()
                        for(let i = 0; i < value.length; i++) {
                            if (!value[i].includes(",")) {
                                arr.push(value[i]);
                            }
                        }
                        return arr;
                    }
                    else {
                        return [];
                    }
                }
            },

            // 변경 데이터 저장
            getChange: (value) => {
                state.dataTable.modifyItems.push(value);
            },

            // 프로젝트정보 저장
            saveTabTableList: () => {

                if (state.dataTable.modifyItems.length == 0) {

                    alertManager.alert.warning("변경사항이 없습니다.");

                }
                else {
                    // 사이트노출 배열 -> 문자열
                    state.dataTable.modifyItems.forEach(function (value, index) {
                        if (Array.isArray(state.dataTable.modifyItems[index].vflag_koreit)) {
                            state.dataTable.modifyItems[index].vflag_koreit.sort();
                            state.dataTable.modifyItems[index].vflag_koreit = state.dataTable.modifyItems[index].vflag_koreit.join(",");
                        }
                    })

                    //스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    projectExposure.saveTabTableList(JSON.stringify(state.dataTable.modifyItems)).then((response) => {

                        if (response.success) {

                            alertManager.toast.save();
                            methods.getProjectExposure();
                        }

                    }).finally(() => {

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                    });
                }
            },

        }

        onMounted(() => {

            // 프로젝트 정보 초기조회
            methods.getProjectExposure();
        })

        return {
            state,
            projectExposure,
            methods,
        }
    }
}
</script>